import FinanceApi from '@/services/FinanceApi';

const getBanks = async (config) => {
    return FinanceApi.get('/banks', config);
}

const getOptions= async () => {
    return FinanceApi.get('/banks/options');
}

export default {
    getBanks, getOptions
}
